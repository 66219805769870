import * as React from "react";
import { graphql } from "gatsby";

import Layout from "@components/common/Layout"
import Seo from "@components/common/SEO"
import Top from "@components/blogs/Top"
import Main from "@components/blogs/Main"
import Service from "@components/blogs/Service"

const Blogs = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={'既婚者コラム | 既婚者向け総合メディア - Cuddle Place'}
        description={'既婚者合コン・サークルへの潜入レポートなど、ここでしか読めないCuddle Place（カドル プレイス）編集部のコラム一覧です。セカンドパートナー、オープンマリッジ、婚外恋愛、専用マッチングアプリ、掲示板など、今の既婚者の出会いのリアルを知りましょう。'}
      />
      <Top />
      <Main data={data.allMicrocmsBlog.edges} />
      <Service />
    </Layout>
  )
}

export const query = graphql`
  {
    allMicrocmsBlog(
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
          revisedAt
          author {
            name
            description
            image {
              url
            }
          }
          related_blogs {
            id
            title
            hero_image {
              url
            }
            category
            publishedAt
            revisedAt
          }
        }
      }
    }
  }
`
export default Blogs
